import React from "react"
import clsx from "clsx"
import SanityImage from "../SanityImage"

const ImagesUI = ({ input }) => {
  return (
    <section className={clsx("module-images-ui mb-md col-span-2")}>
      <div className="row">
        {input.images.map((item, i) => (
          <div className="col-md-6 col-xs-12" key={i}>
            <SanityImage input={item} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default ImagesUI
