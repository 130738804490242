import React from "react"
import { Card } from "../type"
import { TypeWrapper } from "../type/TypeContext"
import { _linkResolver } from "../../core/utils"

const ProductListUI = ({ input }) => {
  return (
    <section className="moduel-product-list-ui">
      {/* <pre>{JSON.stringify(input)}</pre> */}
      {input.products.map((node, i) => (
        <TypeWrapper
          fontName={node.slug.current}
          typeface={node.typefaces[0].typeface}
          key={i}
        >
          <Card input={node} index={i} />
        </TypeWrapper>
      ))}
    </section>
  )
}

export default ProductListUI
