import React from "react"
import Marquee from "react-fast-marquee"
import styled from "styled-components"

const Container = styled.div`
  .text {
    /* line-height: 1.2; */
    padding-top: 0.05em;
    padding-bottom: 0.1em;
  }
`

const MarqueeUI = ({ input }) => {
  return (
    <Container className="-mx-md ">
      <Marquee
        gradient={false}
        speed={40}
        play={true}
        className=""
        style={{
          background: input.backgroundColor || "var(--bg)",
          color: input.foregroundColor || "var(--color-primary)",
        }}
      >
        {new Array(20).fill(0).map((v, i) => (
          <div key={i} className="text px-05 text-xl capitalize">
            {input.text}
          </div>
        ))}
      </Marquee>
    </Container>
  )
}

export default MarqueeUI
