import React, { useEffect, useRef, useState } from "react"

const VideoUI = ({ input }) => {
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef()

  useEffect(() => {
    playerRef.current.addEventListener("ended", event => {
      playerRef.current.currentTime = 0
      setPlaying(false)
    })
  }, [])

  useEffect(() => {
    if (playing) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }, [playing])

  return (
    <section
      className="module-video-ui mb-md relative col-span-2"
      onClick={() => setPlaying(!playing)}
    >
      <video
        src={input.video.asset.url}
        className="w-full"
        ref={playerRef}
      ></video>
      {!playing && (
        <div className="absolute left-1/2 top-1/2 cursor-pointer -translate-x-1/2 -translate-y-1/2">
          <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="51.5" cy="51.5" r="51" fill="black" stroke="white" />
            <path
              d="M39.25 29.2824L76 50.5L39.25 71.7176L39.25 29.2824Z"
              fill="black"
              stroke="#09F9DC"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </section>
  )
}

export default VideoUI
