import clsx from "clsx"
import React from "react"
import SanityTexte from "../SanityTexte"

const TexteUI = ({ input }) => {
  // console.log(input)
  const getSize = () => {
    switch (input.columnSize) {
      case "1/2":
        return "w-1/2- col-span-1"
      case "2/2":
        return "w-2/2"
    }
  }
  const size = input.columnSize ? getSize() : "col-span-2"

  return (
    <section
      className={clsx("module-texte-ui portable-text mb-md md:text-lg ", size)}
    >
      <SanityTexte input={input.texte} />
    </section>
  )
}

export default TexteUI
