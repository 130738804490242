import React from "react"
import TexteUI from "./TexteUI"
import TexteImageUI from "./TexteImageUI"
import ImageUI from "./ImageUI"
import ImagesUI from "./ImagesUI"
import VideoUI from "./VideoUI"
import CodeUI from "./CodeUI"
import styled from "styled-components"
import clsx from "clsx"
import ProductListUI from "./ProductListUI"
import MarqueeUI from "./MarqueeUI"

const Container = styled.div`
  .grid {
    margin-right: calc(-1 * var(--gutter));
    margin-left: calc(-1 * var(--gutter));
    section {
      padding-right: var(--gutter);
      padding-left: var(--gutter);
    }
  }
  section.module-texte:nth-child(1) {
    margin-bottom: var(--space-lg);
  }
  &.default {
    section {
      margin-bottom: 5em;
    }
  }
`

const Modules = ({ input, layout }) => {
  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      // console.log(module._type)
      switch (module._type) {
        case "texteUI":
          return <TexteUI key={module._key} input={module} />
        case "imageUI":
          return <ImageUI key={module._key} input={module} />
        case "texteImageUI":
          return <TexteImageUI key={module._key} input={module} />
        case "imagesUI":
          return <ImagesUI key={module._key} input={module} />
        case "videoUI":
          return <VideoUI key={i} input={module} />
        case "codeUI":
          return <CodeUI key={i} input={module} />
        case "productListUI":
          return <ProductListUI key={i} input={module} />
        case "marqueeUI":
          return <MarqueeUI key={i} input={module} />
        default:
          return null
      }
    })
    return _modules
  }
  // console.log(layout)
  return (
    <div>
      {layout === "default" ? (
        <Container className={clsx("modules ", layout)}>
          {_renderModules()}
        </Container>
      ) : (
        <Container className={clsx("modules grid", layout)}>
          {layout === "centered" && (
            <div className="row">
              <div className="col-md-1 hidden-sm"></div>
              <div className="col-xs grid md:grid-cols-2">
                {_renderModules()}
              </div>
              <div className="col-md-1 hidden-sm"></div>
            </div>
          )}
          {layout === "split" && (
            <div className="grid md:grid-cols-2">{_renderModules()}</div>
          )}
        </Container>
      )}
    </div>
  )
}

export default Modules
