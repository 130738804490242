import React from "react"
import clsx from "clsx"
import SanityImage from "../SanityImage"

const ImageUI = ({ input }) => {
  const { link, image } = input
  return (
    <section className={clsx("module-image-ui mb-md col-span-2")}>
      {link ? (
        <a
          href={link.link}
          title={link.label}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SanityImage input={image} />
        </a>
      ) : (
        <SanityImage input={image} />
      )}
    </section>
  )
}

export default ImageUI
