import React from "react";
import clsx from "clsx";
import styled from "styled-components";
//import Highlight from 'react-highlight'

const Pre = styled.pre`
  box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
  white-space: pre-wrap;
  font-family: monospace, monospace;
  max-width: 100%;
  @media all and (max-width: 1080px) {
    // font-size: 5vh;
  }
`;

const CodeUI = ({ input }) => {
  return (
    <section className={clsx("module-code-ui mb-md ")}>
      <Pre className='bg-turquoise text-black p-md md:text-lg'>
        <code>{input.code}</code>
      </Pre>
    </section>
  );
};

export default CodeUI;
